import { MainMenu, useGlobalContext } from '@honeycomb/data';
import {
    Container,
    FlexGrid,
    Header,
    MenuIcon,
    Stacker,
    ActionButton,
    Divider,
    Anchor,
    PhoneIcon,
    Styler,
    HelpIcon,
    useDisclosure,
} from '@honeycomb/ui-core';
import { NavigationMenu } from '@honeycomb/ui';

import { DnataTravelLogo } from '../logos/DnataTravelLogo';

export function ErrorMasthead() {
    const [menuOpen, { open: handleOpenMenu, close: handleCloseMenu }] = useDisclosure(false);

    const {
        resourceStrings: { TELEPHONENUMBER, HC_SUPPORTCENTREURL, MAINMENU_HEADING, HC_SUPPORTCENTRE_LABEL },
        globalContents: { HEADER },
    } = useGlobalContext();

    const telephoneNumber = TELEPHONENUMBER !== '' ? TELEPHONENUMBER : '80036282';
    const phoneHref = `tel:${telephoneNumber.replace(/\s/g, '')}`;
    const supportUrl = HC_SUPPORTCENTREURL !== '' ? HC_SUPPORTCENTREURL : 'https://www.dnatatravel.com/contact-us';
    const supportLabel = HC_SUPPORTCENTRE_LABEL !== '' ? HC_SUPPORTCENTRE_LABEL : 'Help';
    const menuData = HEADER?.content;

    return (
        <>
            <Header component="div" disableGutters={true}>
                <Container>
                    <FlexGrid container={true} alignItems="center" flexWrap="nowrap">
                        <FlexGrid xs="hold">
                            {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
                            <a href="/">
                                <DnataTravelLogo />
                            </a>
                        </FlexGrid>
                        <FlexGrid xs="fill">
                            <Stacker
                                direction="row"
                                alignItems="center"
                                justifyContent="flex-end"
                                spacing={{ xs: 2, m: 6 }}
                            >
                                <>
                                    <Styler display={{ xs: 'none', m: 'block' }}>
                                        <Anchor weight="semiBold" color="accent.base" href={phoneHref} underline="none">
                                            {telephoneNumber}
                                        </Anchor>
                                    </Styler>

                                    <Styler display={{ m: 'none' }}>
                                        <ActionButton
                                            href={phoneHref}
                                            target="_new"
                                            icon={<PhoneIcon />}
                                            aria-label="Call us"
                                        />
                                    </Styler>
                                </>

                                <ActionButton
                                    href={supportUrl}
                                    target="_new"
                                    icon={<HelpIcon />}
                                    aria-label={supportLabel}
                                    hideLabel={{ xs: true, m: false }}
                                    hideIcon={{ m: true }}
                                >
                                    {supportLabel}
                                </ActionButton>

                                {menuData && (
                                    <ActionButton icon={<MenuIcon />} onClick={handleOpenMenu} aria-label="Menu" />
                                )}
                            </Stacker>
                        </FlexGrid>
                    </FlexGrid>
                </Container>
                {menuData && (
                    <NavigationMenu
                        menuData={(menuData as MainMenu) ?? undefined}
                        title={MAINMENU_HEADING}
                        open={menuOpen}
                        onClose={handleCloseMenu}
                    />
                )}
            </Header>
            <Divider />
        </>
    );
}
